.chipone {
  color: #21a9f0;
  background: rgba(33, 169, 240, 0.1);
  font-size: 11px;
  padding: 5px;
  margin: 0 5px;
  line-height: 12px;
  border-radius: 2px;
}
.chipone:hover {
  text-decoration: none;
}
.optinselect .selectoptin.StyledBox-sc-13pk1d4-0.IRSNj {
  margin-top: 10px;
}

tr.drawerbodytext td span.StyledText-sc-1sadyjn-0.fWSbXS {
  font-size: 12px;
  line-height: 1.5;
}
tr.drawerbodytext td span.timetext {
  font-size: 11px;
}
.middlePart tbody tr.drawerbodytext:hover span.StyledText-sc-1sadyjn-0.fWSbXS {
  font-size: 12px !important;
  line-height: 1.5;
}
.middlePart tbody tr.drawerbodytext:hover span.timetext {
  font-size: 11px !important;
}
.detailsSection .ant-drawer-header {
  background: #001528c4;
}
p.detailspart {
  font-size: 12px;
  letter-spacing: 0.5px;
}
.boxDetails {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.5px;
}
span.detailsdate {
  font-weight: 500;
  color: black;
}

span.detailscontent {
  font-weight: 500;
  color: #060708;
  font-size: 13px;
}
span.detailslabel {
  font-weight: 500;
  color: rgba(130, 130, 130, 0.9686274509803922);
}
span.detailslabel.taghead {
  text-align: center;
  font-size: 13px;
  color: #060708;
}
.noterouter_managetable button img {
  width: 13px;
  height: 13px;
  margin-right: 5px;
  margin-bottom: 1px;
}
.noterouter_managetable table tbody {
  background: white !important;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
.noterouter_managetable p {
  margin: 0;
  display: inline-block;
}
.noterouter_managetable button:hover {
  background: #f7f7f7 !important;
}
.noterouter_managetable button:focus {
  outline: none !important;
}
.noterouter_managetable table {
  overflow-x: auto;
}
.noterouter_managetable div:first-child {
  box-shadow: none;
  background: transparent;
}
.noterouter_managetable tr th {
  font-size: 12px !important;
  font-weight: 600;
  letter-spacing: 1px;
  color: #5f5d5d;
  font-family: 'Poppins', sans-serif;
  z-index: 0 !important;
  background-color: transparent !important;
}
/* .noterouter_managetable table tbody tr {
    background: transparent !important;
} */
.noterouter_managetable.btnseparation div:first-child > div:first-child {
  min-height: 35px;
}
.noterouter_managetable.btnseparation tfoot div:first-child > div:first-child {
  min-height: 0;
}
.noterouter_managetable table tfoot {
  overflow-x: auto;
}
.noterouter_managetable tr th {
  z-index: 0 !important;
}
.engagementTab div:first-child {
  border-bottom: none;
}
span.textpart {
  margin-left: 4em;
}
span.textClick {
  margin-left: 10em;
}
.statuschip {
  color: #faad14 !important;
  padding: 3px 10px 3px 10px;
  /* margin: 0 auto; */
  border-radius: 3px;
  /* line-height: 3px; */
  background: rgba(250, 173, 20, 0.25882352941176473);
}
.tagsec {
  background: #f7f7f7;
  padding: 10px;
  margin-bottom: 10px;
}
.labelHead {
  text-align: center;
}
.Managecomp .engagementTab span {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 0;
  border-radius: 2px;
  text-align: center;
  width: 100px;
  outline: none !important;
  box-shadow: none !important;
}
.engagementTab div:first-child {
  margin-left: 0px;
  margin-right: 6px;
}
.engagementTab,
button {
  box-shadow: none !important;
}

.engagementTab span {
  color: #007ade !important;
  background: rgba(0, 122, 222, 0.05) !important;
  border: 1px solid rgba(0, 122, 222, 0.7);
}

.engagementTab[aria-selected='true'] span {
  box-shadow: none !important;
  background: #007ade !important;
  border: 1px solid #007ade;
  color: white !important;
}
.middlePartz {
  padding-top: 1em;
  width: 100%;
}
.middlePartz .StyledBox-sc-13pk1d4-0 {
  border-radius: 4px;
  padding-left: 25px;
  padding-right: 25px;
}
.middlePartz thead .StyledBox-sc-13pk1d4-0 {
  border-bottom: none;
}
.middlePartz tbody.StyledTable__StyledTableBody-sc-1m3u5g-3 {
  border: 1px solid #eae9e9;
  background: white;
}
.middlePartz tbody tr.StyledTable__StyledTableRow-sc-1m3u5g-2 {
  border-bottom: 1px solid #eae9e9;
}

.middlePartz tbody tr:last-child td .StyledBox-sc-13pk1d4-0.eAAZJM {
  border-bottom: none;
}
.middlePartz thead span.StyledText-sc-1sadyjn-0 {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #5f5d5d;
  font-family: 'Poppins', sans-serif;
}
.middlePartz tbody span.StyledText-sc-1sadyjn-0 {
  font-size: 13px;
  font-weight: 550;
  color: #5f5d5d;
  font-family: 'Poppins', sans-serif;
}
.middlePartz span.StyledText-sc-1sadyjn-0 {
  display: inherit;
}
.middlePartz tbody td.StyledTable__StyledTableCell-sc-1m3u5g-0 {
  height: 50px;
  position: relative;
}

.middlePartz table.StyledTable-sc-1m3u5g-6 {
  width: 100%;
}

.engTable {
  padding-left: 25px;
  padding-right: 25px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .selectorg .ant-select {
    width: 100%;
  }
}
