body.html,
#root > div {
  height: 100%;
}

.loginsec {
  height: 100%;
  background: var(--theme\:color\:primary) !important;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.loginfirst {
  background: #fff;
  padding: 35px 45px;
  border-radius: 4px;
  width: 412px;
}
p.logo {
  color: #ffffffbd;
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 17px;
}
.loginheading {
  color: #35506b;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  margin-bottom: 1.7em;
  font-family: 'Poppins', sans-serif;
}
.inputbox.IRSNj {
  padding: 0 8px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0px;
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.inputbox {
  width: 20px;
  height: 20px;
  fill: #656565;
  stroke: #656565;
}
.iOkQrb *[stroke*='#'],
.TfAtP *[STROKE*='#'] {
  stroke-width: 1;
}

.loginsec {
  padding: 8px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}
.loginsec::-webkit-input-placeholder {
  color: #848484;
  font-family: 'Poppins', sans-serif;
}
.loginsec {
  border-bottom: none;
  text-align: center;
}
.loginsec {
  background: #35506b;
  color: white;
  border: none;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}
a.forgot {
  font-size: 13px;
  text-align: center;
  color: #9e9e9e;
  font-family: 'Poppins', sans-serif;
  margin-top: 2em;
  font-weight: 400;
}
a.forgot:hover {
  text-decoration: none;
}
a.forgot span {
  color: #35506b;
}
a.forgot span:hover {
  background: rgba(153, 153, 153, 0.18);
  border-radius: 2px;
}
.logoimg {
  background-repeat: no-repeat;
  background-size: auto;
  height: 10%;
  width: 10%;
  background-position: center;
}
.nrLogo {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

/* .loginsec button.ant-btn.login-form-button.ant-btn-primary {
    width: 100%;
} */
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.loginsec .ant-row.ant-form-item {
  text-align: center;
  margin-bottom: 35px;
}
.loginsec .ant-btn-primary {
  color: #fff;
  background-color: #fc9e3a;
  border-color: #fc9e3a;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 0 25px;
  margin-bottom: 30px;
}
/* .loginfirst .has-error .ant-form-explain, .has-error .ant-form-split{
    font-size: 11px;
    text-align: left;
    position: absolute;
    bottom: -18px;
    left: 1px;
} */
.loginFormNew .ant-form-explain,
.loginFormNew .ant-form-extra {
  color: red;
  line-height: 1.524;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -2px;
  clear: both;
  text-align: left;
  font-size: 11px !important;
  position: absolute !important;
  bottom: -18px;
  left: 1px;
}

.loginFormNew .ant-form-item {
  position: relative;
  font-family: 'Poppins', sans-serif;
}
.loginFormNew .ant-form-item-label {
  z-index: 99;
  position: absolute;
  left: 12px;
  top: -17px;
  background: #ffffff;
  padding: 0 8px;
  height: 22px;
}
.loginFormNew label {
  font-size: 13px !important;
  color: #989797;
}
.loginFormNew .ant-form-item-label label:after {
  content: none;
}
.loginFormNew .ant-input {
  height: 40px;
  font-family: 'Poppins', sans-serif;
}
.frgetpwd {
  font-size: 13px;
  color: #656565;
}
.textcenter {
  text-align: center;
}
.ant-form-item-with-help label {
  color: red;
}
.error {
  color: red;
  text-align: center;
}
.ant-tooltip-placement-topLeft .ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.93);
}
.pwdtooltip span {
  position: relative;
  margin-bottom: 8px;
  display: block;
  font-size: 11px;
}
.pwdtooltip span:after {
  content: '';
  position: absolute;
  bottom: -5px;
  width: 100%;
  height: 0.5px;
  left: 0;
  background: #565656;
}
.pwdtooltip ul {
  padding: 0 15px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.pwdtooltip ul li {
  font-size: 11px;
}
.welcomemsg {
  font-size: 20px;
  letter-spacing: 0.5px;
  margin: 0;
}
.welcomemsginfo {
  font-size: 10px;
  margin-bottom: 20px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .loginFormNew .ant-form-item-label {
    background: #001528;
    width: auto;
  }
  .loginFormNew label {
    color: #ffffff;
  }
  .frgetpwd {
    font-size: 12px;
    color: rgba(224, 222, 222, 0.7686274509803922);
  }
  .loginFormNew input {
    background: transparent;
  }
  .loginfirst {
    background: transparent;
    width: 100%;
    padding: 35px 29px;
  }
  .loginfirst .text-center {
    color: #ffffff;
  }
  a.forgot span {
    color: #e7972d !important;
    font-size: 12px;
  }
  .loginheading {
    color: #fefefe;
    font-size: 20px;
  }
  .loginFormNew .ant-input {
    height: 40px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
  }
}
