.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    color: #fff;
    width: 550px;
    background-color: #005092;
    padding: 20px 16px;
}

.closeButton {
    color: #ffffff !important;
}

.title {
    font-size: 16px;
}

.body {
    padding: 16px;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 16px;
}

.button {
    padding-left: 20px !important;
    padding-right: 20px !important;
    border: none !important;
    min-width: 110px !important;
}

.primaryButton {
    background-color: #5eab00 !important;
}

.primaryButton:hover {
    background-color: #67ba00 !important;
}

.primaryButton:disabled {
    background-color: #cacaca !important;
}

.primaryButton:enabled {
    color: #ffffff;
}

.secondaryButton {
    background-color: #ff6161 !important;
    color: #ffffff !important;
}

.secondaryButton:hover {
    background-color: #ff7a7a !important;
}
