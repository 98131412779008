.ant-layout-content {
  /* flex: auto;
    min-height: 0; */
  width: 100%;
  height: 100%;
}
.ManagePortalUserscomp {
  height: 100%;
}

.ManagePortalUserscomp.optincontent .quilsection {
  padding: 25px !important;
}

.quilsection {
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  /* justify-content: center; */
  /* margin-top: 1em; */
}
.quilsec.IRSNj {
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* padding: 25px; */
  /* margin: 0 auto; */
  border-radius: 4px;
  box-shadow: none;
  /* background: white; */
}
.quilsec1 {
  width: 100% !important;
  height: 100% !important;
}
.quill {
  /* margin-top: 1.8em; */
  width: 100%;
  margin: 1em auto;
  height: 100%;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eaeaea !important;
  overflow-y: scroll;
}
.ql-toolbar.ql-snow {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 1px solid #eaeaea !important;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
  padding: 8px;
  border: none;
}

.ql-container.ql-snow {
  height: auto;
  border-color: #eaeaea !important;
  border: none !important;
}

.ql-editor {
  min-height: 100px;
  max-height: 200px;
}

.ql-editor .ql-blank {
  height: 100px !important;
}

.ql-editor-expanded .ql-editor {
  height: 200px;
}

.ql-editor:focus {
  height: 200px;
}

.quilsection .fWSbXS {
  font-size: 20px;
  line-height: 24px;
  color: #001528;
  padding: 0 15px;
}
.optinheading {
  padding: 0 !important;
}
.quilsection .cuRQxH {
  font-size: 14px;
}
.quilsection .dvDJyW {
  background: #f7f7f7;
  width: 100%;
  padding: 20px;
}
.fLjoaM {
  margin: 0px;
  font-size: 14px;
  line-height: 14px;
  color: #0e2d5c;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.optincontentsavebtn .savebtn.StyledButton-sc-323bzc-0.lhIxjU {
  background: #e7972d;
  border: none;
  height: 35px;
  line-height: 9px;
  border-radius: 4px;
  color: #fff;
  margin-top: 2em;
  margin-left: 2px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 30px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
.optincontentsavebtn .savebtn.StyledButton-sc-323bzc-0.lhIxjU:hover {
  background: #da8e2a;
  border: none;
}
.noterouter_manage .savebtn.StyledButton-sc-323bzc-0.lhIxjU {
  background: #e7972d;
  border: none;
  height: 35px;
  line-height: 9px;
  border-radius: 4px;
  color: #fff;
  margin-top: 2em;
  margin-left: 2px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 30px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
.noterouter_manage .savebtn.StyledButton-sc-323bzc-0.lhIxjU:hover {
  background: #da8e2a;
  border: none;
}
.savebtn {
  background: #e7972d;
  border: none;
  height: 35px;
  line-height: 9px;
  border-radius: 4px;
  color: #fff;
  margin-top: 2em;
  margin-left: 2px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 30px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
.savebtn:hover {
  background: #da8e2a;
  border: none;
}
.quilsection .ant-select-selection--single {
  height: 35px;
  width: 245px;
}
.quilsection .ant-select-selection {
  background-color: #e5e5e5;
  border-radius: 2px;
  border: none;
}
.quilsection .ant-select-selection__rendered {
  line-height: 34px;
}
