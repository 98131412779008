.bulkGroupAssignmentButton {
  margin-right: 15px !important;
}

.AMSSyncRow {
  font-size: 11px;
  color: grey;
  margin-left: -5px;
  margin-top: -4px;
  float: left;
  height: 40px;
}

.auditSync {
  font-size: 9px;
  color: grey;
  padding-bottom: 5px;
  margin-left: 10px;
}

.tagTableBufferGap {
  height: 45px !important;
}

.chooseFileBtn {
  color: #494949 !important;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background: #ffffff;
  border: 4px solid #494949 !important;
  display: inline-block;
  transition: all 0.4s ease 0s;
  margin-left: 28%;
  height: 50px;
}

.chooseFileBtn:hover {
  color: #ffffff !important;
  background: #e7972d;
  border-color: #e7972d !important;
  transition: all 0.4s ease 0s;
  cursor: pointer;
}

.ManageContainer {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background: rgb(247, 247, 247) !important;
}

.topTabs .fXJmvC,
.topTabs .boePIs {
  justify-content: flex-start;
  margin: 17px 25px -4px 25px !important;
  position: relative;
}

.topTabs > div:first-child {
  justify-content: flex-start;
  padding-top: 16px;
  align-items: center;
  padding-left: 24px;
}

.membersTab span {
  color: #007ade !important;
  background: rgba(0, 122, 222, 0.05) !important;
  border: 1px solid rgba(0, 122, 222, 0.7);
}

.membersTab[aria-selected='true'] span {
  box-shadow: none !important;
  background: #007ade !important;
  border: 1px solid #007ade;
  color: white !important;
}

.ManageContainer .membersTab span {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 0;
  margin-right: 6px;
  border-radius: 2px;
  text-align: center;
  width: 100px;
}

.membersTab div:first-child {
  border-bottom: none;
  margin: 0 6px 6px 0;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 0;
  border-radius: 2px;
  text-align: center;
}

.FieldsAndData span {
  width: 125px !important;
  color: #6c7075 !important;
  background: #fff !important;
  border: 1px solid #d4d7d9;
}

.spacebetween1.StyledBox-sc-13pk1d4-0 {
  height: 60px;
  flex-direction: row;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eae9e9;
}

.secondSectionpart1.StyledBox-sc-13pk1d4-0 {
  justify-content: flex-end;
  flex-direction: row;
}

.modaltxt {
  margin-bottom: 0;
  color: #888888;
  text-align: center;
  font-weight: 300;
  font-size: 15px;
  font-family: 'poppins', sans-serif;
}

.secondSectionpart1
  .Select__StyledSelectBox-sc-17idtfo-1.hFvoei.StyledBox-sc-13pk1d4-0.erazuK {
  margin-top: 12px;
  border: 1px solid rgb(233, 233, 233);
}

.secondSectionpart1 svg.StyledIcon-ofa7kd-0.dSllCE {
  stroke: #80808094;
  width: 16px;
}

.ant-popover-inner-content p {
  margin-bottom: 1px;
  margin-top: 1px;
  margin-right: 3px;
  margin-left: 4px;
  color: black;
  font-size: 10px;
}

.ant-popover-inner-content {
  padding: 5px 8px;
  color: rgba(0, 0, 0, 0.65);
}

.ManageGrommetBox {
  overflow: hidden !important;
  position: relative;
  height: 100%;
}

.ManageTableContainer {
  height: 100%;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

/*Upload contacts by CSV drawer*/
.UploadContactDrawer .MuiPaper-root {
  width: 750px !important;
}

.UploadContactDrawerHeader {
  color: white !important;
  font-size: 15px;
  height: 60px;
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}

.UploadContactDrawerContainer {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.UploadContactFieldsHeader {
  padding: 6px 0;
  font-size: 14px;
  display: flex;
  border-radius: 3px;
  width: 100%;
  background-color: rgb(243, 243, 243);
}

.UploadContactFieldsBoxes {
  font-size: 14px;
  border: 1px solid #d4d7d9;
  padding: 5px 0;
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  margin-bottom: -1px;
}

.FieldSaveButtons {
  width: 80px;
  background-color: #5eab00 !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
}

.UIDSelectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-select-dropdown {
  /*Added this because MUI drawer and the ANTD select hate each other*/
  z-index: 9999 !important;
}

.UIDSelectionHeader {
  border-radius: 3px;
  width: 100%;
  background-color: rgb(243, 243, 243);
  display: flex;
  justify-content: space-around;
}

.UIDSelectionOptions {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.CSVMatchingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(
    100% - 249px
  ); /*249 is about the total size of everything above container*/
}

.CSVMatchingHeader {
  padding: 6px 0;
  font-size: 12px;
  display: flex;
  border-radius: 3px;
  width: 100%;
  background-color: rgb(243, 243, 243);
}

.CSVMatchingOptionBox {
  border: 1px solid #d4d7d9;
  padding: 4px 0;
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  margin-bottom: -1px;
}

.DataGridContainer {
  height: 100%;
  width: 100%;
}

.alertFlagIcon {
  display: flex;
  align-items: center;
}

.ManageDataGrid {
  height: 97%;
  background: white;
}

.ManageTagsDataGrid {
  height: 94%;
  background: white;
}

.manage-member-tag {
  background-color: #d4d7d9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  width: 80px;
  padding-top: 1px !important;
  text-align: center;
  cursor: pointer !important;
  border-radius: 4px !important;
}

.manage-tags-count-container {
  width: 80px;
  padding-top: 1px !important;
  text-align: center;
  cursor: pointer !important;
  border-radius: 4px !important;
}

.modal-scroll {
  overflow-y: scroll;
  max-height: 350px;
}

.modal-scroll::-webkit-scrollbar {
  width: 10px;
}

.modal-scroll::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 99vmax;
}

.modal-scroll::-webkit-scrollbar-track {
  background: rgb(212, 211, 211);
  border-radius: 99vmax;
}

.manageMembersModalStyling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 200px;
  background-color: #fff;
}

.manageTagsModalStyling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 350px;
  background-color: #fff;
}

.manageModalHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  width: 550px;
  height: 60px;
  background-color: #0e2d5c;
}

.manageMembersModalBody {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.manageTagsModalBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.manageModalList {
  height: 160px;
  margin-bottom: 20px;
}

.manageModalListItem {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 2px;
  padding: 5px 0 5px 10px;
}

.addMenuContainer .MuiPaper-root {
  margin-top: 2px;
  border: 1px solid #5eab00;
}

.addMenuContainer .MuiList-padding {
  padding: 0;
}

.syncMenuContainer .MuiPaper-root {
  margin-top: 2px;
  border: 1px solid #007ade;
}

.syncMenuContainer .MuiList-padding {
  padding: 0;
}

.GroupDrawers .MuiPaper-root {
  width: 720px !important;
}

.RecipDrawerLarge .MuiPaper-root {
  width: 810px !important;
}

.RecipDrawer .MuiPaper-root {
  width: 660px !important;
}

.RecipDrawerHeader {
  color: white !important;
  font-size: 16px;
  height: 60px;
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 24px;
}

/* Media query */
@media screen and (max-height: 1439px) {
  .ManageDataGrid {
    height: 98%;
  }

  .ManageTagsDataGrid {
    height: 96%;
  }
}

@media screen and (max-height: 1080px) {
  .ManageDataGrid {
    height: 97%;
  }

  .ManageTagsDataGrid {
    height: 94%;
  }
}

@media screen and (max-height: 900px) {
  .ManageDataGrid {
    height: 96%;
  }
}

@media screen and (min-width: 360px) and (max-width: 992px) {
  .spacebetween1.StyledBox-sc-13pk1d4-0 {
    padding-left: 13px;
    width: 592px;
  }

  .secondSectionpart1.StyledBox-sc-13pk1d4-0 {
    /* margin-left: 10px; */
    margin-top: 0;
  }

  .secondPart1 {
    /* background: #f1f1f163; */
    overflow: auto;
  }
}
