.UploadCSVResultsDrawer .MuiPaper-root {
  width: 750px !important;
}

#root,
.mainlayout {
  height: 100%;
}

.fullLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000000;
  display: flex;
  left: 0;
  right: 0;
}

.fullLoader .contentDiv {
  color: white;
  text-align: center;
  font-size: 7em;
}

.fullLoader .contentDiv p {
  font-size: 14px;
}

.contentDiv p {
  font-size: 14px;
}

.fullLoader p {
  margin-top: 20px;
  color: white;
}

.progressMeter {
  color: #ffffff;
  padding: 10px 18px;
  text-align: center;
  cursor: pointer;
}

.progressMeter:hover {
  color: #ffffffdd;
}

.progressMeter > a {
  color: inherit;
}

.ant-layout {
  background: #f7f7f7 !important;
}

.ant-layout-header {
  line-height: 0 !important;
  padding: 12px 25px !important;
  height: auto !important;
}
.pageheading {
  color: #001528;
  font-size: 17px;
  line-height: 29px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}
.navbarsec.StyledBox-sc-13pk1d4-0.brLKxe {
  background: #ffffff;
  padding-left: 12px;
  position: fixed;
  z-index: 999;
  width: 100%;
}
.tWoPx,
.ijJJkS,
.gAXUqz,
.bkpoc {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 13px 0 !important;
}

.menuSvg svg.StyledIcon-ofa7kd-0.jAaLrm {
  stroke: #0f2e5dc4;
}
.navbarsec .arPcl {
  background: #3b4f69;
  border: none;
  border-radius: 2px;
  color: #fff;
  padding: 4px 22px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  font-weight: 500;
}
.navbarsec .DOiol {
  padding: 0 !important;
  background: transparent !important;
}
.navbarsec .bYWoze {
  background: transparent;
}
.navbarsec
  .Select__StyledSelectBox-sc-17idtfo-1.dIZMVW.StyledBox-sc-13pk1d4-0.erazuK {
  font-size: 13px;
}
.navbarsec
  input.Select__SelectTextInput-sc-17idtfo-0.lmyIUy.StyledTextInput-sc-1x30a0s-0.gQHocF {
  color: black;
  opacity: 0.8;
}
.navbarsec svg.StyledIcon-ofa7kd-0.ildldD {
  stroke: #d6d6d6;
}

span.StyledText-sc-1sadyjn-0.fLjoaM {
  font-size: 12px;
  line-height: 9px;
}
.StyledDrop-sc-16s5rx8-0.kswylr.StyledBox-sc-13pk1d4-0.iVWgBr {
  max-height: 110.5px !important;
}
/* button.StyledButton-sc-323bzc-0.iauybm {
    padding: 6px !important;
} */
.nyobr {
  background: #f7f7f7;
}
svg *[stroke*='#'],
svg *[STROKE*='#'] {
  stroke-width: 1;
}
.firstbox {
  position: relative;
}
.firstbox svg {
  width: 28px;
  height: 28px;
  fill: #fff;
  stroke: #fff;
}
.firstbox {
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
}
.firstbox {
  padding: 0;
  width: 100%;
  height: 95px;
  box-shadow: none;
  background-color: white;
  border: 0.5px solid #58aede;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 15px;
}
.mainsection.nyobr {
  align-items: flex-start;
  justify-content: flex-start;
  background: #f7f7f7;
  padding-left: 215px;
  /* margin-top: 0.5em */
}
.viewsec {
  padding: 0px 50px 0px 50px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.mainsection {
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: auto;
}
.mainfirst {
  width: 100%;
  flex-direction: row;
}
.iconsection {
  background: #58aedf;
  width: 55px;
  height: 55px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  margin-right: 20px;
  position: absolute;
  left: 10px;
}

.textsec {
  flex-direction: column;
  margin-top: -36px;
  border-radius: 15px;
}
span.sectxt.ratedifftext {
  font-size: 14px !important;
  font-weight: 200;
}

span.firsttxt {
  color: #848484;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}
span.sectxt {
  color: #0e2d5c;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  /* margin-top: 5px; */
}
.tablesec.DOiol {
  padding: 0;
  margin-top: 14px;
}
.tablesec table {
  width: 100%;
  margin-top: 0em;
}
.tablesec {
  width: 100%;
}
.tablesec th {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  color: #0e2d5c;
  opacity: 0.7;
}
.tablesec tbody {
  border: 1px solid #efefef;
}
.tablesec .folWQp {
  padding-bottom: 9px;
  border: none;
}
.tablesec tbody tr {
  border-radius: 2px;
}
.tablesec tbody tr:hover {
  cursor: pointer;
}
.tablesec tbody tr .eAAZJM {
  background: #fff;
  color: #0e2d5c;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.tablesec tbody tr th .eAAZJM {
  border-radius: 2px 0 0 2px;
}

.tablesec tbody tr td:last-child .eAAZJM {
  border-radius: 0px 2px 2px 0;
  position: relative;
  justify-content: center;
}
/* .tablesec tbody tr td:last-child .eAAZJM:before{
    content: '';
    position: absolute;
    background: #75e428;
    width: 6px;
    left: 0px;
    height: 6px;
    border-radius: 30px;
} */
.ant-badge-status-success {
  background-color: #75e428;
}
.ant-badge-status-error {
  background: #f04633;
}
.ant-badge-status-warning {
  background-color: #efaa2f;
}
.ant-badge-status-processing {
  background-color: #21a9f0;
}
.ant-badge-status-processing:after {
  display: none;
}
/* .tablesec tbody tr:nth-child(2) td:last-child .eAAZJM:before,.tablesec tbody tr:nth-child(6) td:last-child .eAAZJM:before{
    content: '';
    position: absolute;
    background: #f04633;
   
} */
/* .tablesec tbody tr:nth-child(3) td:last-child .eAAZJM:before,.tablesec tbody tr:nth-child(7) td:last-child .eAAZJM:before{
    content: '';
    position: absolute;
    background: #efaa2f;
  
} */
/* .tablesec tbody tr:nth-child(4) td:last-child .eAAZJM:before,.tablesec tbody tr:nth-child(8) td:last-child .eAAZJM:before{
    content: '';
    position: absolute;
    background: #21a9f0; 
} */
.tablesec tbody tr:first-child td:nth-child(4) .eAAZJM span,
.tablesec tbody tr:nth-child(5) td:nth-child(4) .eAAZJM span {
  color: #539e1f;
  background: rgba(117, 228, 40, 0.18);
}
.tablesec tbody tr:nth-child(2) td:nth-child(4) .eAAZJM span,
.tablesec tbody tr:nth-child(6) td:nth-child(4) .eAAZJM span {
  color: #21a9f0;
  background: rgba(33, 169, 240, 0.1);
}
.tablesec tbody tr:nth-child(3) td:nth-child(4) .eAAZJM span,
.tablesec tbody tr:nth-child(7) td:nth-child(4) .eAAZJM span {
  color: #efaa2f;
  background: rgba(239, 170, 47, 0.15);
}
.tablesec tbody tr:nth-child(4) td:nth-child(4) .eAAZJM span,
.tablesec tbody tr:nth-child(8) td:nth-child(4) .eAAZJM span {
  color: #21a9f0;
  background: rgba(33, 169, 240, 0.1);
}
.tablesec tbody tr td:nth-child(4) .eAAZJM span {
  font-size: 11px;
  padding: 0 5px;
  border-radius: 2px;
}
.tablesec tbody tr {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}
.tablesec tbody tr .dndifk {
  font-size: 14px;
  line-height: 24px;
  font-weight: inherit;
  font-family: 'Poppins', sans-serif;
  color: #0e2d5c;
}
.sidenavbarsecwidthmanage.StyledBox-sc-13pk1d4-0.eRoOKE {
  width: 215px;
  position: fixed;
  height: 100%;
}
.sidenavbarsecwidthmanage1.StyledBox-sc-13pk1d4-0.eRoOKE {
  width: 65px;
  position: relative;
}
.sidenavbarsecwidthmanage1.eRoOKE {
  animation: dYVibJ 0s forwards, khbOwN 0s forwards;
}
.mainheadtext {
  position: relative;
  width: 100%;
  background: transparent;
  color: #051a3a;
  margin-bottom: 24px;
  padding: 10px 0;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid #e8e8e8;
}
.mainheadtext:before {
  position: absolute;
  content: '';
  background: #028ff2;
  height: 3px;
  width: 65px;
  left: 0;
  bottom: -2px;
}
.viewsec .mainheadtext {
  padding: 15px 0 10px 0;
}
.mainheadtext span {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  color: #848484;
  margin-left: 10px;
}

.scheduledHeadText {
  position: relative;
  width: 100%;
  background: transparent;
  color: #051a3a;
  margin-bottom: 7px;
  padding: 10px 0;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid #e8e8e8;
}
.scheduledHeadText:before {
  position: absolute;
  content: '';
  background: #028ff2;
  height: 3px;
  width: 65px;
  left: 0;
  bottom: -2px;
}
.viewsec .scheduledHeadText {
  padding: 15px 0 10px 0;
}
.scheduledHeadText span {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  color: #848484;
  margin-left: 10px;
}

/* responsive start */
@media screen and (min-width: 320px) and (max-width: 768px) {
  .mainfirst {
    flex-flow: column;
  }
  .middlePartview .widthadjust.StyledBox-sc-13pk1d4-0.eoKPJA {
    border-bottom: none;
    width: 125px;
  }
  .ant-layout-header {
    padding: 10px !important;
  }
  .ant-layout-header .menuiconresponsive {
    position: absolute;
    right: -14px;
  }
  .pageheading {
    font-size: 13px;
    line-height: 34px;
  }
  /* viewsection start */
  .firstbox {
    margin-bottom: 10px;
    height: 65px;
  }
  /* viewsection end */
  .iconsection {
    width: 40px;
    height: 40px;
    margin: 0;
  }
  .firstbox svg {
    width: 20px;
    height: 20px;
    fill: #fff;
    stroke: #fff;
  }
  span.firsttxt {
    color: #848484;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
  }
  .firstbox .textsec {
    margin-left: 45px;
  }
  span.sectxt {
    margin-top: 0;
    font-size: 20px;
  }
}

/* responsive end */
