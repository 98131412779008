.select {
    width: 100%;
    margin-bottom: 4px;
}

.pickerDropdownFooter {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;
    padding: 12px 12px 8px;
}

.closePickerDropdownButton {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: none;
    color: #1890ff;
    transition: color 250ms;
}

.closePickerDropdownButton:hover {
    color: #40a9ff;
}


.noAvailableOptionsMessage {
    display: block;
    text-align: center;
}

.selectionList {
    padding: 0 !important;
    height: 300px;
    overflow: auto;
}

.selectionList.isEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedOption:hover::before {
    content: '>';
    font-weight: 700;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #1890ff;
}
