.selectsection {
  background: #f1f1f163;
  border-bottom: 2px solid #e5e5e5;
  padding: 25px !important;
}

.selectsection .dSllCE {
  width: 17px;
  height: 17px;
  fill: #999999;
  stroke: #999999;
}
.selectsection input {
  height: 35px;
  font-size: 15px;
}
.selectsection input::placeholder {
  color: #838080;
  font-size: 15px;
  font-weight: 400;
}
.selctsection .cuRQxH {
  padding: 7px !important;
}
.selectsection button {
  width: 35% !important;
}
.fLjoaM {
  font-size: 12px;
}
.eEPVhE {
  padding: 7px 12px;
}
.chipone {
  color: #21a9f0;
  background: rgba(33, 169, 240, 0.1);
  font-size: 11px;
  padding: 5px;
  margin: 2px 5px !important;
  line-height: 12px;
  border-radius: 2px;
}
.chipone:hover {
  text-decoration: none;
}
.optinselect .selectoptin.StyledBox-sc-13pk1d4-0.IRSNj {
  margin-top: 10px;
}
/* .ant-drawerpart .StyledBox-sc-13pk1d4-0.eoKPJA {
    width: 150px;
} */
tr.drawerbodytext td span.StyledText-sc-1sadyjn-0.fWSbXS {
  font-size: 12px;
  line-height: 1.5;
}
tr.drawerbodytext td span.timetext {
  font-size: 11px;
}
.middlePart tbody tr.drawerbodytext:hover span.StyledText-sc-1sadyjn-0.fWSbXS {
  font-size: 12px !important;
  /* color: #191818; */
  line-height: 1.5;
  /* opacity: 0.8; */
  /* font-family: 'Poppins', sans-serif; */
}
.middlePart tbody tr.drawerbodytext:hover span.timetext {
  font-size: 11px !important;
}
.detailsSection .ant-drawer-header {
  background: #001528c4;
}
p.detailspart {
  font-size: 12px;
  letter-spacing: 0.5px;
}
span.detailsdate {
  font-weight: 500;
  color: black;
}

span.detailscontent {
  font-weight: 500;
  color: #060708;
  font-size: 13px;
}
span.detailslabel {
  font-weight: 500;
  color: rgba(130, 130, 130, 0.9686274509803922);
}
span.detailslabel.taghead {
  font-size: 13px;
  color: #060708;
}
.noterouter_managetable.btnseparation div:first-child > div:first-child {
  min-height: 35px;
}
.noterouter_managetable.btnseparation tfoot div:first-child > div:first-child {
  min-height: 0;
}
span.textpart {
  margin-left: 4em;
}
.statuschip {
  color: #faad14 !important;
  padding: 3px 10px 3px 10px;
  /* margin: 0 auto; */
  border-radius: 3px;
  /* line-height: 3px; */
  background: rgba(250, 173, 20, 0.25882352941176473);
}
.tagsec {
  background: #f7f7f7;
  padding: 10px;
  margin-bottom: 10px;
}

.fieldDrawer {
  overflow: 'auto';
  padding-left: 1%;
  padding-right: 1%;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .selectorg .ant-select {
    width: 100%;
  }
}
