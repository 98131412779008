.confirmCancel {
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 4px;
  color: white;
  background: #e7972d;
  padding: 0 20px;
  height: 35px;
  border: 0;
  width: auto;
  float: right;
  margin-right: 43%;
}
.popupCancel {
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 4px;
  /* color: white; */
  /* background: #e7972d; */
  padding: 0 20px;
  height: 35px;
  border: 0;
  width: auto;
  margin-left: 37%;
  cursor: pointer;
}

.popupModal {
  font-size: 12px;
}
.popupModal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.popupModal > .content {
  width: 100%;
  padding: 5px 5px;
  text-align: center;
}
.popupModal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.popupModal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.middlePartview {
  width: 100%;
  overflow: auto;
}
.middlePartview .widthadjust {
  border-bottom: none;
}
.middlePartview thead tr spa {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  color: #0e2d5c;
  opacity: 0.7;
}
.gutter-example .ant-row > div {
  background: transparent;
  border: 0;
}

.makeTableMoreCompact {
  position: absolute;
}
.deleteScheduledMessageButton {
  margin-right: 13px;
  margin-top: 6px;
}

.addAlertBtn {
  margin-bottom: 6px;
  font-size: 14px;
}

.messageSubject {
  white-space: nowrap;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: relative;
}

.noterouterAlert {
  min-height: 225px;
  max-height: 225px;
  overflow-y: scroll;
  background: #fbfbf9;
  padding: 6px 20px;
  box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
}
.scheduledMessages {
  min-height: 250px;
  max-height: 250px;
  overflow-y: scroll;
  background: #ffffff;
  padding: 6px 20px;
  box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
}
.noterouterAlert .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.52);
  font-size: 13px;
  line-height: 20px;
}
.loadmorebtn {
  font-size: 12px;
  padding: 0 9px;
  height: 27px;
  background: #aeaeae;
  color: #ffffff;
  border: 0;
}
.loadmorebtn:hover {
  font-size: 12px;
  padding: 0 9px;
  height: 27px;
  background: #aeaeae;
  color: #ffffff;
  outline: none;
  border: 0;
}
.noterouterAlert .ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.82);
}
.noterouterAlert::-webkit-scrollbar {
  display: none !important;
}
.noterouterAlert .ant-list-item-meta-title {
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.79);
}
.noterouterAlert .ant-list-item-action li p {
  font-size: 11px;
  margin: 0;
}
.noterouterAlert .anticon-bell {
  font-size: 1.7em;
  padding: 13px;
  border-radius: 1000px;
  color: #fc929d;
}
.percentagearrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9%;
  left: 0;
  right: 0;
}

.definatelyARealClass {
  font-size: 12px;
}

.cardsecrow {
  position: relative;
}

.arrowup {
  color: #04c304;
  width: 17px;
  height: 24px;
  margin-left: 10px;
}
.arrowdown {
  color: #f8545c;
  width: 17px;
  height: 24px;
  margin-left: 10px;
}
span.sectxt.ratedifftext {
  font-size: 18px;
  font-weight: 200;
}
.sectxt.ratedifftext.lowerthan0 {
  color: #f8545c;
}
.sectxt.ratedifftext.greaterthan0 {
  color: #04c304;
}
.scheduledMessageStyle {
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
}
.scheduledMessageStyle h5 {
  float: left;
  padding: 6px 15px;
}
.scheduledMessageStyle button {
  font-size: 12px;
}
.scheduledHeadText {
  margin-bottom: 17px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .noterouterAlert {
    min-height: 360px;
    max-height: 360px;
  }
  .percentagearrow {
    top: -6%;
  }
}
