.ant-drawer-header {
  background: #001528;
}

.ant-drawer-title {
  color: white;
}

button.ant-drawer-close {
  color: white;
}

button.ant-drawer-close:hover {
  color: white;
}
