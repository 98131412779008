.resetsec.bYWoze {
  height: 100vh;
  background: url('../../Images/loginback.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.resetfirst.IRSNj {
  background: #fff;
  padding: 35px 45px;
  border-radius: 4px;
  width: 473px;
}

.resetfirst p {
  color: #35506b;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  margin-bottom: 1.7em;
  font-family: 'Poppins', sans-serif;
}
.inputbox.IRSNj {
  padding: 0 8px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0px;
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.inputbox .iOkQrb {
  width: 20px;
  height: 20px;
  fill: #656565;
  stroke: #656565;
}
.iOkQrb *[stroke*='#'],
.TfAtP *[STROKE*='#'] {
  stroke-width: 1;
}

.resetsec .iQGivQ {
  padding: 8px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}
.resetsec .iQGivQ::-webkit-input-placeholder {
  color: #848484;
  font-family: 'Poppins', sans-serif;
}
.resetsec .gZSidW {
  border-bottom: none;
  text-align: center;
}
.resetsec .iauybm {
  background: #35506b;
  color: white;
  border: none;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
  margin: 10px auto 0;
  padding: 8px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}
a.forgot {
  font-size: 13px;
  text-align: center;
  color: #9e9e9e;
  font-family: 'Poppins', sans-serif;
  margin-top: 2em;
  font-weight: 400;
}
a.forgot:hover {
  text-decoration: none;
}
a.forgot span {
  color: #35506b;
}
.logoimg {
  background: url('../../infrastructure/ui/logo/logo.v-primary.c-light.svg');
  background-repeat: no-repeat;
  background-size: auto;
  height: 100px;
  width: 100%;
  background-position: center;
}

.resetsec button.ant-btn.login-form-button.ant-btn-primary {
  width: 100%;
  color: #fff;
  background-color: #334e6b;
  border-color: #314c69;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
