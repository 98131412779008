.quilsec .cuRQxH {
  padding: 5px 11px;
  background: #7d767600;
  font-weight: 500;
}
.quilsec .dSllCE {
  width: 20px;
  height: 20px;
  fill: #ababab;
  stroke: #ababab;
}
