.ApplyTagDrawerHeader {
  color: white !important;
  font-size: 16px;
  height: 60px;
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}
.ApplyTagSaveButtons {
  width: 80px;
  background-color: #5eab00 !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
}
.tagselect {
  align-content: center;
}
.apply-tag-helper-text {
  padding-top: 4px;
  padding-bottom: 12px;
  color: black;
}
.userlistapplyform .ant-form-item-control {
  flex: auto !important;
}
