.Listcontainer {
  width: 100%;
  margin: 0 auto;
  background: rgb(247, 247, 247) !important;
}

.listDiv {
  overflow: hidden !important;
  position: relative;
}
.listDiv .spindiv {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  z-index: 9;
}
.listDiv .ant-spin.ant-spin-spinning {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 11%;
}

.applycancelsec .exportSvg .anticon {
  margin-right: 4px;
  font-size: 12px;
}
.applycancelsec .firstButton.canclebtn .StyledBox-sc-13pk1d4-0.hHwlHq {
  background: #aaaaaa;
  color: #ffffff;
  width: 80px;
}
.applycancelsec .firstButton.canclebtn .StyledBox-sc-13pk1d4-0.hHwlHq:hover {
  background: #f50057;
  color: #ffffff;
}
.applyButtonAddTag {
  margin-right: 6% !important;
  align-items: center !important;
  border-radius: 2px !important;
  padding: 6px 8px !important;
  background: #e7972d !important;
  color: #fff !important;
  margin-top: 10px !important;
}

.cancelButtonAddTag {
  margin-right: 6% !important;
  align-items: center !important;
  border-radius: 2px !important;
  padding: 6px 8px !important;
  background: grey !important;
  color: #fff !important;
  margin-top: 10px !important;
}

.memberListTable {
  padding-top: 1em;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}
