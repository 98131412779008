.GroupDrawerHeader {
  color: white !important;
  font-size: 16px;
  height: 60px;
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}
.GroupSaveButtons {
  width: 80px;
  background-color: #5eab00 !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
}
.applynowbtn:hover {
  border-color: lightgray !important;
  background-color: lightgray !important;
  color: #000e1e85 !important;
}
.tagbtnofsave:hover {
  background-color: #fed8b1 !important;
  color: #fff !important;
}
.topicswitch.ant-switch:after {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 2.3px;
}
.topicswitch .ant-switch-inner {
  margin-left: 20px;
}
.topicswitch.ant-switch-checked .ant-switch-inner {
  margin-left: 6px;
}
.modal {
  font-size: 12px !important;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  text-align: center;
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.preferenceFormSelectOrder {
  position: relative;
  width: 120%;
}
.dvDJyW {
  background: #f7f7f7;
}
.Edittext {
  position: relative;
  font-family: 'Poppins', sans-serif;

  width: 100%;
  background: transparent;
  color: #0e2d5c;
  padding-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
.Edittext:before {
  position: absolute;
  content: '';
  height: 3px;
  width: 12%;
  left: 0;
  bottom: -2px;
}
span.mandatorytext {
  font-size: 13px;
  opacity: 0.8;
}
