.forgotfirst p.firsttext {
  color: #35506b;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}
.forgotfirst {
  background: #fff;
  padding: 40px 46px;
  border-radius: 4px;
  width: 412px;
}
/* p.secondtextPart2 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 13px;
    color: #afabab;
    font-weight: 400;
    text-align: center;
} */
p.secondtext {
  margin-bottom: 30px;
  font-size: 10px;
  line-height: 14px !important;
  color: #afabab;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
a.login {
  font-size: 13px;
  text-align: center;
  color: #9e9e9e;
  font-family: 'Poppins', sans-serif;
  margin-top: 30px;
  font-weight: 400;
}
a.login:hover {
  text-decoration: none;
}
a.login span {
  color: #35506b;
}
.inputemailbox {
  padding: 0 8px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0px;
  width: 100%;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.inputemailbox {
  width: 20px;
  height: 20px;
  fill: #656565;
  stroke: #656565;
}
.forgotfirst .ant-form-item-control-wrapper.ant-col-xs-24.ant-col-sm-16 {
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .forgotfirst {
    background: #001528;
  }
  .forgotfirst p.firsttext {
    color: #e9ecef;
    font-size: 20px;
  }
  p.secondtext {
    font-size: 11px;
  }
}
