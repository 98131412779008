.optinmsgsec .ant-spin-nested-loading > div > .ant-spin {
  min-height: 100%;
  background: #d8d8d8;
  border-radius: 5px;
  width: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.optinmsglogocontent {
  position: relative;
}
.optinmsglogocontent .ant-spin.ant-spin-spinning {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d8d8d8;
  z-index: 9;
}
.optinmsglogocontent .optinmsgsec {
  align-items: center;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  height: 100% !important;
  overflow-y: scroll;
}

.white-bg {
  background-color: white !important;
}

.load_optin {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  /* margin: 20px 0; */
}

.load_optin,
.optinPart {
  height: 100vh;
}

#message-display-iframe {
  height: 100vh;
}

.optinmsgsec .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.dynamicLogo {
  width: 380px;
  height: 160px;
  margin: 5px auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.dynamicLogo .uploadedimg {
  width: 100%;
  height: 100%;
}
.optinmsgcontent {
  /* background: #ffffff; */
  border-radius: 4px;
  width: 50%;
  margin: 0 auto;
  padding: 0;
}
.optinmsgform {
  background: #ffffff;
  padding: 30px;
}
.loginsec.optinmsgsec .ant-row.ant-form-item {
  margin-bottom: 20px;
}

.optinPart-center {
  background-color: rgb(14, 45, 92);
}

.optinPart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.optinPart.IRSNj {
  background: #fff;
  padding: 35px 45px;
  border-radius: 4px;
  width: 100%;
  margin: 10px auto 0;
}
.successmsg {
  text-align: center;
  background: rgba(0, 128, 0, 0.72);
  color: white;
  padding: 2px 0;
}
i.anticon.anticon-check-circle.roundcheck {
  margin-right: 7px;
}
.successmsg p {
  margin-top: 4px;
  margin-bottom: 4px;
}
p.headerPara {
  margin-top: 1em;
  font-size: 15px;
  font-weight: 600;
}
.msgpara {
  font-size: 13px;
}
.optinPart .ant-col-23 {
  width: 100%;
}
.optinmsgsec .ant-row.ant-form-item {
  text-align: left !important;
}
.optinPart .ant-form-item-label label:after {
  display: none;
}
p.exclaimpara {
  color: #35506b;
  font-weight: 600;
}
p.optlastPara {
  margin-bottom: 2em;
  font-size: 13px;
}

.optinmsgsec .btnofcnfrm {
  width: auto !important;
  padding: 0 15px !important;
}

.onptinInfo {
  color: #484848;
  background: #f3f3f3;
  font-size: 11px;
  padding: 12px 15px;
  position: relative;
}
.onptinInfo::before {
  position: absolute;
  content: '';
  width: 3px;
  height: 100%;
  left: 0;
  top: 0;
  background: #7d7b7b;
}
/* responsive start */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .optinmsgsec .load_optin {
    width: 100%;
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .optinmsgcontent {
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
  }
  .optinmsglogocontent {
    position: relative;
  }
  .optinmsglogocontent .ant-spin.ant-spin-spinning {
    width: 100%;
    height: 100%;
    max-height: none !important;
    background: #d8d8d8;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .load_optin {
    width: 100%;
    border-radius: 0;
    margin-bottom: 0;
  }
  .optinmsgcontent {
    background: transparent;
    border-radius: 0;
    width: 80%;
    margin: 0 auto;
    padding: 15px;
  }
  .optinmsgsec {
    height: 100%;
  }
}
