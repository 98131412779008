.fieldDrawerBulkGroupAssignmentButton {
  margin-left: 25px !important;
}

.fieldDrawerBulkGroupAssignmentButton:enabled {
  border-color: #6c7075 !important;
}

.fieldDrawerBulkGroupAssignmentButton:disabled {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.fieldsCSVAddBtn {
  color: black !important;
}

.createFieldDatagridContainer {
  height: 450px;
  width: 100%;
  margin-top: 25px;
}

.uploadCSVBtn {
  border-radius: 25px !important;
  margin-bottom: 25px !important;
  padding: 5px 25px !important;
}

.fieldDrawer .MuiPaper-root {
  width: 750px !important;
}

.FieldDrawerHeader {
  color: white !important;
  font-size: 16px;
  height: 60px;
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}

.fileUploadContainer {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FieldSaveButtons {
  width: 80px;
  background-color: #5eab00 !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
}

.UIDSelectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-select-dropdown {
  /*Added this because MUI drawer and the ANTD select hate each other*/
  z-index: 9999 !important;
}

.UIDSelectionHeader {
  border-radius: 3px;
  width: 100%;
  background-color: rgb(243, 243, 243);
  display: flex;
  justify-content: space-around;
}

.UIDSelectionOptions {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.CSVMatchingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(
    100% - 249px
  ); /*249 is about the total size of everything above container*/
}

.CSVMatchingHeader {
  padding: 6px 0;
  font-size: 12px;
  display: flex;
  border-radius: 3px;
  width: 100%;
  background-color: rgb(243, 243, 243);
}

.CSVMatchingOptionBox {
  border: 1px solid #d4d7d9;
  padding: 4px 0;
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  margin-bottom: -1px;
}

.ContactFieldsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ContactFieldsHeader {
  padding: 10px 0;
  font-size: 12px;
  display: flex;
  border-radius: 3px;
  width: 100%;
  background-color: rgb(243, 243, 243);
}

.ContactFieldsBoxes {
  border: 1px solid #d4d7d9;
  padding: 15px 0;
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  margin-bottom: -1px;
}

.ContactFieldsBoxes .MuiInput-underline:before,
.ContactFieldsBoxes .MuiInput-underline:after {
  border-bottom: none !important;
}

.ContactFieldsLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000000;
  display: flex;
  left: 0;
  right: 0;
}

.ContactFieldsLoading .contentDiv {
  color: white;
  text-align: center;
  font-size: 7em;
}

.ContactFieldsLoading p {
  margin-top: 20px;
  color: white;
}

.disabledButtonStyle {
  background-color: rgba(0, 0, 0, 0.09) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.modalStyling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 350px;
  background-color: #fff;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  width: 550px;
  height: 60px;
  background-color: #0e2d5c;
}

.modalBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.modalList {
  height: 160px;
  margin-bottom: 20px;
}

.modalListItem {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 2px;
  padding: 5px 0 5px 10px;
}

.tableCellBorder {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding-left: 3px;
  padding-top: 2px;
  cursor: text;
}

@media screen and (min-height: 700px) {
  .createFieldDatagridContainer {
    height: 245px;
  }
}

@media screen and (min-height: 768px) {
  .createFieldDatagridContainer {
    height: 275px;
  }
}

@media screen and (min-height: 800px) {
  .createFieldDatagridContainer {
    height: 300px;
  }
}

@media screen and (min-height: 850px) {
  .createFieldDatagridContainer {
    height: 365px;
  }
}

@media screen and (min-height: 900px) {
  .createFieldDatagridContainer {
    height: 415px;
  }
}

@media screen and (min-height: 950px) {
  .createFieldDatagridContainer {
    height: 475px;
  }
}

@media screen and (min-height: 1000px) {
  .createFieldDatagridContainer {
    height: 500px;
  }
}

@media screen and (min-height: 1080px) {
  .createFieldDatagridContainer {
    height: 575px;
  }
}

@media screen and (min-height: 1150px) {
  .createFieldDatagridContainer {
    height: 650px;
  }
}

@media screen and (min-height: 1200px) {
  .createFieldDatagridContainer {
    height: 700px;
  }
}

@media screen and (min-height: 1300px) {
  .createFieldDatagridContainer {
    height: 800px;
  }
}

@media screen and (min-height: 1350px) {
  .createFieldDatagridContainer {
    height: 865px;
  }
}

@media screen and (min-height: 1440px) {
  .createFieldDatagridContainer {
    height: 935px;
  }
}
