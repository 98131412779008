.testEmailFormWrapper {
  text-align: center;
}

.scheduleInput {
  width: 100%;
}

.scheduleTimeFieldWrapper {
  margin-top: 12px;
}
