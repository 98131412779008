.detailPanel {
  text-align: center;
}

.export-drawer {
  position: absolute;

  padding: 5px 10px 0 10px;
  height: 32px;
  border-radius: 2px;
  border-color: #1890ff !important;
  background: #1890ff;
  color: white;
  box-shadow: none;
  /* vertical-align: text-bottom; */
  text-align: center;
  z-index: 205;
  transition: opacity 500ms ease;
}

.export-drawer:hover {
  position: absolute;
  color: white;
  background: #2394fd;
  opacity: 0.8;
}
