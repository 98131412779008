.groupsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.groupsTitle {
  display: inline-block;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  color: #1a1a1a;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 7px;
  position: relative;
}

.groupsTitle::before {
  position: absolute;
  content: '';
  background: #e7972d;
  height: 3px;
  width: 115%;
  bottom: -2px;
}

.newGroupButton {
  padding: 8px 12px !important;
  color: #007ade !important;
}

.ContactDetailDrawerContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  overflow-y: scroll;
}
.ContactSaveButtons {
  width: 80px;
  background-color: #5eab00 !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
}
.ContactDrawerHeader {
  color: white !important;
  font-size: 16px;
  /* height: 60px; */
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}
.ContactDetailDrawerContainer input {
  font-family: 'Poppins', sans-serif;
  padding: 8px 11px;
  font-weight: 500;
  background: #fcfcfc !important;
  border-radius: 2px;
  border: 1px solid #efefef;
  width: 100%;
  font-size: 13px;
  box-shadow: none;
}
.ContactDetailDrawerContainer textarea {
  padding: 6px 11px;
  font-weight: 500;
  background: #fcfcfc;
  border-radius: 2px;
  border: 1px solid #efefef;
  width: 100%;
  font-size: 12px;
  box-shadow: none;
  color: rgba(14, 45, 92, 0.77);
}

.ContactDrawerLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000000;
  display: flex;
  left: 0;
  right: 0;
}

.ContactDrawerLoading .contentDiv {
  color: white;
  text-align: center;
  font-size: 7em;
}

.ContactDrawerLoading p {
  margin-top: 20px;
  color: white;
}

.Emailcheck.ant-switch:after {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 2.3px;
}
.ant-switch-checked:after {
  left: 97%;
  margin-left: 0;
}
.Emailcheck .ant-switch-inner {
  margin-left: 20px;
}
.Emailcheck.ant-switch-checked .ant-switch-inner {
  margin-left: 6px;
}

label.checkidenti1.ant-checkbox-wrapper {
  margin-right: 2em;
}
p.identifont {
  font-size: 14px;
  margin-bottom: 0;
  padding: 6px 0;
}
.taggedtable {
  width: 100% !important;
  margin-left: 0px;
  margin-bottom: 12px;
}

.taggedtable tr td {
  border-bottom: none;
}

.taggedtable .taggedHead {
  background: rgba(230, 229, 229, 0.4588235294117647);
  border-bottom: 1px solid rgba(230, 229, 229, 0.4588235294117647);
}
.taggedtable tr td:first-child {
  width: 58%;
}

.taggedtable tr td {
  padding: 6px 12px !important;
}

.taggedpara {
  margin-top: 13px;
  color: rgba(0, 21, 42, 0.58);
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
.taggedtable tbody {
  border: 1px solid #efefef;
  margin-bottom: 2em;
}
.taggedheader {
  margin-bottom: 0;
  margin-top: 0;
  color: rgba(0, 21, 42, 0.85);
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}
.taggedbody {
  margin-bottom: 5px;
  margin-top: 5px;
}
p.addmember {
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  color: #001529;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 7px;
  position: relative;
}
.addmember:before {
  position: absolute;
  content: '';
  background: #e7972d;
  height: 3px;
  width: 11%;
  bottom: -2px;
}
