.container {
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.bnone {
  border: none !important;
}
.width100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.p15 {
  padding: 15px;
}
.pt15 {
  padding-top: 15px;
}
.pb15 {
  padding-bottom: 15px;
}
.pl15 {
  padding-left: 15px;
}
.pr15 {
  padding-right: 15px;
}
.ml20 {
  margin-left: 20px;
}
.p0 {
  padding: 0;
}
.p1em {
  padding: 1em;
}
.pt1em {
  padding-top: 1em;
}
.pb1em {
  padding-bottom: 1em;
}
.pl1em {
  padding-left: 1em;
}
.pr1em {
  padding-right: 1em;
}
.m15 {
  margin: 15px;
}
.mt15 {
  margin-top: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.m0 {
  margin: 0;
}
.m1em {
  margin: 1em;
}
.mb5em {
  margin-bottom: 5em;
}
.mt5em {
  margin-top: 5em;
}
.mt1em {
  margin-top: 1em;
}
.mb1em {
  margin-bottom: 1em;
}
.ml1em {
  margin-left: 1em;
}
.mr1em {
  margin-right: 1em;
}
.height100vh {
  height: 100vh;
}
.height10vh {
  height: 10vh;
}
.flex {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.height100 {
  height: 100%;
}
.table {
  display: table;
  height: 100%;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}
.ashback {
  background: #f7f7f7;
}
.white {
  color: white;
}
.black {
  color: #1a1a1a !important;
}
.font15 {
  font-size: 15px;
}
.line-height {
  line-height: 20px;
}
.inline-block {
  display: inline-block;
}
.display-none {
  display: none;
}
.list-inline {
  list-style: none;
  text-align: center;
  padding-left: 0;
}
.list-inline li {
  display: inline;
  margin: 10px;
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: antdDrawerFadeIn 0.2s cubic-bezier(0.7, 0.3, 0.1, 1);
}
button.ant-drawer-close {
  padding: 20px 10px !important;
}
.ant-notification {
  z-index: 999999 !important;
  top: auto !important;
  bottom: 24px !important;
}
.ant-notification-notice {
  padding: 16px 24px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #001529 !important;
  line-height: 1.5;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
}
.ant-notification-notice-message {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.65) !important;
  margin-bottom: 8px;
  line-height: 24px;
  display: inline-block;
}
.ant-notification-notice-close {
  position: absolute;
  right: 22px;
  top: 19px !important;
  color: rgba(255, 255, 255, 0.65) !important;
  outline: none;
}
.ant-notification-notice-icon-error {
  color: #f75354 !important;
}
.ant-tooltip {
  z-index: 10000 !important;
}
.overflowhidden {
  overflow: hidden;
}
/*Material UI Global Style Changes*/
.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important; /*Removes outline from cell and column header when you click on one*/
}
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: rgba(
    24,
    144,
    255,
    0.08
  ) !important; /*Selected row background color*/
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #1890ff !important; /*Selected row checkbox color*/
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: #1890ff !important; /*the slider of the checkbox color*/
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #1890ff !important; /*the track the slider sits on color*/
}
.MuiFormLabel-root.Mui-focused {
  color: black !important; /*Text of input color when in edit*/
}
.MuiInput-underline:after {
  border-bottom: 2px solid black !important; /*underline color of input when in edit*/
}
.MuiButton-textPrimary {
  color: black !important; /*button text color*/
}
.MuiButton-root {
  border-radius: 3px; /*border radius of buttons*/
}
.MuiButton-label {
  /*Make button text not change (default is all capitalized)*/
  text-transform: none;
}
.MuiTab-root {
  /*Make tab text not change (default is all capitalized)*/
  text-transform: none !important;
}

.parent-margin > .MuiDrawer-paper {
  margin-right: 425px;
}

.ant-picker-dropdown {
  /*Had to change z-index to be above 1250 so that birthday date picker is not hidden under manage add form*/
  z-index: 1300;
}

.mx-5 {
  margin: auto 10px;
}

.grecaptcha-badge {
  display: none;
}
