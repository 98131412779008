.SendMessagecomp {
  padding: 3px 15px !important;
}
button.ant-btn.Editbtn {
  font-size: 17px;
  font-weight: 500;
}
p.BeloweditPara {
  font-size: 11px;
  color: rgb(125, 123, 123);
  margin-top: 1em;
  line-height: 17px;
}
.LeftcardHeader .ant-btn:hover,
.LeftcardHeader .ant-btn:focus {
  color: #fff;
  background-color: #e7972d;
  border-color: #e7972d;
  font-family: 'Poppins', sans-serif;
}

.scrollList {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #333;
  padding: 6px 12px;
  font-size: 10px;
}

.topSelectionRow {
  padding-bottom: 12px;
  border-bottom: 1px solid #d8d8d8;
}

.manageloadingdiv {
  position: relative;
  height: 100%;
  width: 100%;
}
.manageloadingdiv .spindiv {
  position: absolute;
  top: 0;
  height: 95vh;
  width: 100%;
  background: #f1f1f1;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spindiv {
  padding-top: 100px;
  display: table;
  text-align: center;
  margin: 0 auto;
}

.countdownclock {
  display: inline-table;
  padding-right: 100px; /* offset the 'position absolute' in the clock */
  padding-top: 50px;
  padding-bottom: 50px;
}
.emailprogress {
  margin-right: 50px;
  position: relative;
}
.emailprogress::after {
  content: 'Email';
  position: absolute;
  bottom: -39px;
  left: 32%;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
}
.ant-popover-inner-content {
  padding: 12px;
}
/* .spindiv .ant-progress-circle-trail {

    stroke: #010102e6;

} */
.textprogress {
  position: relative;
}
.textprogress::after {
  content: 'Text';
  position: absolute;
  bottom: -39px;
  left: 38%;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
}
.progressdiv {
  position: absolute;
  margin-top: 40px;
}
.messageFields {
  position: absolute;
  text-align: center;
  top: 10%;
  font-size: 14px;
}

.dateAndTimeFields {
  position: absolute;
  text-align: center;
  top: 25%;
  font-size: 14px;
}

.finishedQueued {
  max-width: 650px;
  text-align: center;
  font-size: 16px;
}

.previewMsg {
  text-align: center;
  font-size: 10px;
}
.cancelDiv {
  padding-top: 10px;
}
.cancelbutton {
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: 1px hidden rgba(206, 107, 26, 0.5);
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  width: auto;
}
.cancelbutton:hover {
  background-color: rgba(255, 0, 0, 1);
  color: white;
}

.exitDiv {
  padding-top: 10px;
}
.exitbutton {
  background-color: rgba(206, 107, 26, 0.5);
  border: 1px hidden rgba(206, 107, 26, 0.5);
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  width: auto;
}

.popcancel {
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 4px;
  /* color: white; */
  /* background: #e7972d; */
  padding: 0 20px;
  height: 35px;
  border: 0;
  width: auto;
  margin-left: 25px;
  cursor: pointer;
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.manageloadingdiv .ant-spin.ant-spin-spinning {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 5%;
}
.Managecomp.ManagePortalUserscomp
  .spindiv
  .ant-spin.ant-spin-show-text
  .ant-spin-text {
  color: #000;
}

.ant-spin-text {
  max-width: 600px;
  margin: 0 auto;
  color: #000;
  font-size: 1em;
}

.resolutionbtn {
  background: transparent;
  border: none;
  box-shadow: none;
}
.resolutionbtn:hover {
  background: transparent;
}
.resolutionbtn:focus {
  background: transparent;
}
.resolutionbtn svg {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  fill: #cccccc;
}
/* .resolutionbtn [ant-click-animating-without-extra-node]:after{
   border-color:transparent;
}
.resolutionbtn [ant-click-animating-without-extra-node='true']:after{
    border-color:transparent;
} */
/* .resolutionbtn [ant-click-animating-without-extra-node]:after, .ant-click-animating-node{
   content:none;
   box-shadow: none;
} */
.ant-click-animating-node {
  content: none;
}
.activetog svg {
  fill: #000000;
}

/* Left card start */
.SendMessagecomp .ant-card.LeftCard.ant-card-bordered {
  border: none;
  background: transparent;
}
.LeftcardHeader {
  /* border-bottom: 1px solid #d8d8d8; */
  margin-bottom: 13px;
}
p.secdivision {
  text-align: center;
  margin: 1em 0;
  font-size: 17px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}
.Proimg {
  background: url(../../Images/padpro.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 75vh;
  width: auto;
}
.phoneimg {
  background: url(../../Images/phone.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: auto;
}

.Emailsec {
  padding: 3em;
  max-height: 60vh;
  min-height: 60vh;
  overflow: scroll;
}
.Textsec {
  padding: 6em 31px;
  max-height: 80vh;
  min-height: 80vh;
  overflow: scroll;
}
.schedulelater .datetimehead {
  font-size: 13px;
  margin: 0;
}
.ant-form-item-label {
  line-height: 12px;
}
.timezone {
  margin-bottom: 0;
}

/* Left card end */

/* Right card start */

/* Scheduling options */
.ant-card.RightCardoptions {
  margin-bottom: 2em;
}
.RightCard .ant-card-body {
  padding: 10px;
}
/* Datepicker */
p.datestatemnt {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 12px;
}
.timezone .ant-form-item-label label {
  color: #ffffff;
}
.SendMessagecomp .ant-form-item-label label:after {
  display: none;
}
.datecheck {
  margin-top: 80px;
}
.schedulingcheck .ant-checkbox-inner {
  border: none;
  background-color: #d9d9d9;
}
.schedulingcheck .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0f2e5dc9;
  border-color: transparent;
}
.schedulingcheck .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.schedulingcheck .ant-checkbox:hover .ant-checkbox-inner,
.schedulingcheck .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: transparent;
}
.schedulingcheck .ant-checkbox-checked .ant-checkbox-inner:after {
  top: 2px;
  left: 5px;
}
.ant-card.RightCard.ant-card-bordered {
  border: none;
}
.SendMessagecomp label {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #000000c4;
}
.SendMessagecomp .paddingRight0 {
  padding-right: 0 !important;
}

/* Right card end */
.ant-row.previewTopBar {
  padding: 7px 3px !important;
}

/* Confirm Button */
.cnfrmbtn_row {
  margin-top: 1em;
}
.previewTopBar .btnofcnfrm {
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 4px;
  color: white;
  background: #e7972d;
  padding: 0 20px;
  height: 35px;
  border: 0;
  width: auto;
  float: right;
}
.previewTopBar .btnoftest {
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 4px;
  color: #001528ab;
  background: #d2d2d2;
  padding: 0 20px;
  height: 35px;
  border: 0;
  width: auto;
  margin-right: 12px;
  margin-left: 12px;
  float: right;
}
.previewTopBar .schedulebtn {
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  color: #001528ab;
  background: #d2d2d2;
  padding: 0;
  height: 35px;
  border: 0;
  float: right;
  width: 180px;
}

.previewTopBar .popup-content button {
  float: inherit;
}

.previewTopBar .popup-content {
  max-width: 500px;
}

.RightCard .ant-form-item-control-wrapper,
.RightCard .ant-calendar-picker,
.RightCard .ant-form-item-control,
.RightCard .ant-form-item-children {
  width: 100% !important;
}
.RightCard .ant-form-item-children .ant-time-picker {
  width: 100%;
}
.desk_tab_mobview {
  margin: 0 auto;
}
.previewiframe {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 15px 0 15px 15px;
  margin: 6px 0;
  background-color: white;
}
.previewiframe .laptop iframe,
.previewiframe .tablet iframe,
.previewiframe .mobile iframe {
  transform: scale(0.6);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  -moz-transform-origin: top left;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #fff;
  border-width: 0;
  height: 85vh;
}

.previewiframe .laptop iframe,
.previewiframe .tablet iframe {
  width: 100%;
}

.mobile {
  height: 100%;
  z-index: 5;
  background: transparent;
  margin: 0 auto;
}
.mobile iframe {
  width: 360px;
  margin: 0 auto;
  transform: scale(1);
}

.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tablet {
  width: 73.5%;
  z-index: 3;
  margin: 0 auto;
}
.tablet iframe {
  width: 100%;
  height: 1024px;
  padding-top: 22px;
  background: transparent;
  transform: scale(1);
}
.laptop {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 2;
}
.laptop iframe {
  width: 1280px;
  height: 1024px;
  transform: scale(0.734);
  -webkit-transform: scale(0.734);
  -o-transform: scale(0.734);
  -ms-transform: scale(0.734);
  -moz-transform: scale(0.734);
  background: transparent;
  overflow: hidden;
}
.schedulelater .savebtn {
  float: right;
  margin: 0;
  height: 30px;
  padding: 0 10px;
}
.schedulelater .savebtn:hover {
  color: #ffffff;
}
.verticalline {
  position: relative;
  padding-right: 12px;
  margin-right: 12px;
}
.verticalline:after {
  content: '';
  height: 100%;
  width: 1px;
  background-color: #c1c1c1;
  position: absolute;
  right: 0;
  top: 0;
}
.messageInfo {
  padding: 0;
}

.messageInfoText p,
.messageInfoText p b {
  margin: 0;
}

.desk_tab_mobicon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.previewTopBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.message-preview-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.message-preview-container p {
  font-size: 12px;
}

.message-preview-btn-container {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.testClass {
  width: 88vw;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f1f1f1;
}
.timeZoneCopy {
  font-size: 10px;
  margin-top: 5px;
}
