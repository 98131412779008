.ant-table table {
  margin: 0;
  width: 100%;
}

td.ant-table-cell,
th.ant-table-cell {
  padding: 7px !important;
}

.recipient-table-container {
  display: flex;
  flex-direction: column;
}

.recipient-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 6px;
}

.recipient-table-btn {
  width: 100%;
  max-width: 75px;
  align-self: flex-end;
}

.recipient-table-container {
  margin-top: 23px;
}

.recipient-table-container .ant-pagination-item,
.recipient-table-container .ant-pagination-jump-next,
.recipient-table-container .ant-pagination-jump-prev {
  display: none;
}

.recipient-table-container .ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
}

.recipient-table-container .ant-pagination-options {
  margin-left: auto;
}

.recipient-table-container .anticon-message,
.recipient-table-container .anticon-mobile {
  margin-left: 20px;
}
