.uploadlogosec {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.uploadlogosec form {
  background: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px;
  width: 350px;
  height: 350px;
  border-radius: 50%;
}
/* .uploadlogosec img{
    width: 40%;
    margin: 10px auto;
} */
.uploadbtn {
  background: #e7972d;
  border: none;
  height: 35px;
  max-width: 350px;
  line-height: 9px;
  border-radius: 0 0 1px 1px;
  color: #fff;
  padding: 7px 35px;
  width: 100%;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 30px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
.uploadbtn:hover {
  background: #e7972d;
  color: #fff;
}
.uploadimg {
  width: 35%;
}
.uploadedimg {
  width: 100%;
  height: auto;
}
.uploadinput {
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: 999;
  opacity: 0;
  cursor: pointer;
}
.uploadlogosec label {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  text-align: center;
  font-size: 15px;
  color: #888888;
}
.ant-upload.ant-upload-select-picture-card {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  height: 350px;
  /* border-radius: 50%; */
  background: #999;
  padding: 50px;
  margin: 0;
}
.ant-upload-text {
  font-family: 'Poppins', sans-serif;
  color: #828282;
  font-size: 16px;
  margin-top: 5px;
}
