#preview-design-col {
  flex: 0 0 100%;
  max-width: 100%;
}

.preview-design-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  box-sizing: border-box;
  width: 100%;
  margin: 0;
}

.backtoedit {
  background: #e7e7e773;
  border: 0;
  box-shadow: none;
  font-size: 16px !important;
  border-radius: 5px;
}

.backtoedit:hover {
  background: rgba(0, 21, 40, 0.47);
  color: #ffffff;
}

.preview-design {
  padding: 0;
}

.preview-design-modal .ant-modal-close {
  display: none;
}

.ant-modal-wrap .preview-design-modal {
  width: 75vw !important;
}

.ant-modal-body {
  padding: 0px !important;
}
