.createNewGroupButton {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: none;
    color: #1890ff;
    transition: color 250ms;
}

.createNewGroupButton:hover {
    color: #40a9ff;
}
